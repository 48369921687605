export const regionNoCountry = {
  code: 'noCountry',
  displayToUser:  'No country',
  value: null
}

export const regions = [
  'BE',
  'FI',
  'FR',
  'GB',
  'NL',
  'SE',
  'UK',
  'DE',
  'NO',
  'PL',
  'DK'
]

// Remove this when we want to release the ability to show and update the users' roles (backend fixes needed, doesn't fully work as of 13.05.2024)
export const enableUserRoles = true