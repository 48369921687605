export const firmware_products = {
  backlightSwitch: 'BacklightSwitch',
  displayswitch: 'Displayswitch',
  mainbox: 'Mainbox',
  remoteControl: 'Switchboard',
  pir: "Pir",
  climatesensor: "ClimateSensor",
  magneticsensor: "MagneticSensor",
  epower: "e-Power",
  evheater: "EV-Heater",
  tracker: "Tracker"
}

export const getFirmwareTypeName = (type) => {
  switch (type.toLowerCase()) {
    case 'ble':
      return 'Bluetooth'
    case 'mcu':
    case 'stm':
      return 'Application'
    case 'tcu':
      return 'Telematics'
    case 'bmb':
      return 'BMS Master Bootloader'
    case 'bm':
      return 'BMS Master'
    case 'bsb':
      return 'BMS Slave Bootloader'
    case 'bs':
      return 'BMS Slave'
    case 'cad':
      return 'Charger AC/DC'
    case 'cdd':
      return 'Charger DC/DC'
    case 'cc':
      return 'Charger CAN'
    default:
      return undefined
  }
}

export function getFirmwareType(product, type) {
  let shortType
  switch (type) {
      case 'Application':
          shortType = 'stm'
          break
      case 'Bluetooth':
          shortType = 'ble'
          break
      case 'Telematics':
          shortType = 'tcu'
          break
      case 'BMS Master Bootloader':
          shortType = 'bmb'
          break
      case 'BMS Master':
          shortType = 'bm'
          break
      case 'BMS Slave Bootloader':
          shortType = 'bsb'
          break
      case 'BMS Slave':
          shortType = 'bs'
          break
      case 'Charger AC/DC':
          shortType = 'cad'
          break
      case 'Charger DC/DC':
          shortType = 'cdd'
          break
      case 'Charger CAN':
          shortType = 'cc'
          break
      default:
          shortType = 'unknown'
  }
  return product.toLowerCase() + '-' + shortType
}

export function checkIfCurrent(vehicle, product, type, version) {
  const typeFirmware = getType(vehicle, product, type)
  if (!version || !typeFirmware)
    return
  return typeFirmware ? typeFirmware.version.split('-beta')[0] === version.split('-beta')[0] : false
}

export function checkIfGreaterMajorVersion(vehicle, product, type, version) {
  const typeFirmware = getType(vehicle, product, type)
  if (!typeFirmware) return false

  const oldVersion = typeFirmware.version

  const oldMajorVersion = oldVersion.split('-beta')[0].split('.')[0]
  const newMajorVersion = version.split('-beta')[0].split('.')[0]

  return newMajorVersion > oldMajorVersion
}

export function compareVersions(oldVersion, newVersion) {
  const oldSplit = oldVersion.split('-beta')[0].split('.')
  const newSplit = newVersion.split('-beta')[0].split('.')
  for (let i = 0; i < oldSplit.length; i++) {
      if (oldSplit[i] < newSplit[i])
          return 1
      else if (oldSplit[i] > newSplit[i])
          return -1
  }
  return 0
}

export function getType(vehicle, product, type) {
  if (!vehicle || !product || !type)
      return undefined

  const productFirmwares = getProduct(vehicle, product)
  if (!productFirmwares)
      return undefined

  let shortType
  switch (type) {
      case 'Application':
          shortType = 'mcu'
          break
      case 'Bluetooth':
          shortType = 'ble'
          break
      case 'Telematics':
          shortType = 'tcu'
          break;
      default:
          return undefined
  }
  return productFirmwares.find(x => x.name === shortType)
}

export function getProduct(vehicle, product) {
  let firmwareToCheck
  switch (product.toLowerCase()) {
      case 'mainbox':
          firmwareToCheck = vehicle.firmware
          break
      case 'displayswitch':
          firmwareToCheck = vehicle.displaySwitches && vehicle.displaySwitches[0] && vehicle.displaySwitches[0].firmwares
          break
      default:
          return undefined
  }
  return firmwareToCheck
}

export function hasAvailableFirmware(firmwares, product, type) {
  if (!firmwares || firmwares.length < 1) {
    return false
  }

  let betas = firmwares.betas ?? []
  let all = firmwares.all ?? []
  const all_firmwares = betas.concat(all)

  const found = all_firmwares.filter(fw => fw.product === product && fw.type === getFirmwareTypeName(type))

  return found?.length > 0
}