import React, { useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { fetchTemplates, removeTemplate } from '../../../state/actions/templates'
import SearchWidget from '@modul-connect/shared/components/molecules/searchWidget';
import View from '@modul-connect/shared/components/atoms/view';
import Page from '@modul-connect/shared/components/atoms/page';
import TableWidget from '@modul-connect/shared/components/molecules/tableWidget';
import { useNavigate, useParams, Route } from 'react-router-dom';
import Template from './template/template'
import ModalRemoveItem from '@modul-connect/shared/components/molecules/modalRemoveItem';

const Templates = ({ themes, templates, fetchTemplates, savingLoadingCustomer, removeTemplateFromServer, role }) => {
  const [name, setName] = useState('');
  let filterTimer = null

  const navigate = useNavigate()
  const { id } = useParams()

  const [selectedTemplateId, setSelectedTemplateId] = useState(null)
  const [selectedTemplate, setSelectedTemplate] = useState([])
  const [removeTemplate, setRemoveTemplate] = useState(false)
  const [preparedTemplates, setPreparedTemplates] = useState([])


  const columns = [
    {
      id: 'name',
      label: 'Template',
      width: themes.device != 'mobile' && 70,
      hide: []
    },
    {
      id: 'shortId',
      label: 'Saved From',
      width: themes.device != 'mobile' && 70,
      hide: []
    },
    {
      id: 'appliedTemplateDate',
      label: 'Latest application',
      width: themes.device != 'mobile' && 70,
      hide: []
    }
  ];

  React.useEffect(() => {
    // If the user is jumping to the "vehicle" page directly via link
    if (id) {
      setSelectedTemplateId(id)
    }

    return () => {
      setSelectedTemplateId(null)
    }
  }, [id])

  useEffect(() => {
    const templates_prepared = templates.data?.map((item, i) => ({
      ...item,
      id: item.id ?? `${item.boxId.replace('vehicle/', '')}~${item.name}`,
    }))
    setPreparedTemplates(templates_prepared)
  },[templates])

  const setFilterAfterDelay = (newFilter) => {
    if (filterTimer)
      clearTimeout(filterTimer)
    
    filterTimer = setTimeout(() => { setName(newFilter) }, 1000)
  }

  const handleClick = (_, id) => {
    navigate(`/reports/others_templates/${id}`);
    setSelectedTemplateId(id)
  }

  if (selectedTemplateId) {
    return (
      <Template 
      templateId={selectedTemplateId} 
      deselect={() => setSelectedTemplateId(null)}/>    
    )
  }

  return (
    <View>
      <SearchWidget
        title={ 'Templates' }
        onChange={ (event) => { setFilterAfterDelay(event.target.value) } }
        placeholder={ 'Search for template...' }
        initialValue={ name }
      />

      <Page>
        <TableWidget
          loadingStatus={savingLoadingCustomer.fetchCustomers}
          data={ preparedTemplates }
          totalItems={ templates?.total ?? 0 }
          columns={ columns }
          onFetchData={ fetchTemplates }
          searchStr={ name }
          themes={ themes }
          onRemove={role && role === 1 ? (_, id) => {
            const selectedTemplate = preparedTemplates.find(t => t.id === id)
            setSelectedTemplate(selectedTemplate)
            setRemoveTemplate(true) 
          } : null }
          onClickRow={ handleClick }
        />


    <ModalRemoveItem
    header={'DELETE TEMPLATE'}
    content={"You're about to remove the template: " + selectedTemplate.name}
    openModal={removeTemplate}
    onClose={() => {
      setRemoveTemplate(false)
    }}
    onSubmit={() => {
      setRemoveTemplate(false)
      removeTemplateFromServer(selectedTemplate)
    }}
    />
    </Page>
    </View>
  )
}

const mapStateToProps = ({
  templates,
  themes,
  savingLoadingCustomer,
  role,
}) => ({
  templates,
  themes,
  savingLoadingCustomer,
  role,
})

const mapDispatchToProps = dispatch => ({
  fetchTemplates: (options) => dispatch(fetchTemplates(options)),
  removeTemplateFromServer: (template) => dispatch(removeTemplate(template))

})

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
