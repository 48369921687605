import { apply, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    fetchVehicle,
    fetchVehicleConfig,
    fetchVehicles,
    fetchCustomerVehicles,
    fetchTemplateVehicles,
    fetchBoxesRegistered,
    fetchBoxesDisconnected,
    fetchDisconnectedHistory,
    fetchUsers,
    fetchUsersRegistered,
    fetchCustomers,
    fetchCustomersByRegion,
    fetchCustomer,
    addVehicleToCustomer,
    removeVehicleFromCustomer,
    fetchCustomerUsers,
    fetchWorkshopUsers,
    fetchFirmwares,
    fetchFirmwareBlob,
    uploadFirmware,
    designateFirmwareAsStable,
    deleteFirmware,
    remoteUpgrade,
    fetchIsUpgrading,
    fetchVehicleHistory,
    fetchVehicleTextRecords,
    fetchDebugStatus,
    setDebugStatus,
    fetchFirmwaresReport,
    fetchVehiclesByFirmware,
    fetchDeviceTwin,
    fetchUser,
    fetchTemplate,
    fetchTemplates,
    saveConfigAsTemplate,
    applyTemplateToBox,
    postRemoveTemplate
} from '../../utils/requests';


export function* requestsHandler() {
    yield takeEvery('FETCH_VEHICLE', fetchVehicle)
    yield takeEvery('FETCH_VEHICLE_CONFIG', fetchVehicleConfig)
    yield takeEvery('FETCH_VEHICLE_HISTORY', fetchVehicleHistory)
    yield takeEvery('FETCH_VEHICLE_TEXT_RECORDS', fetchVehicleTextRecords)
    yield takeEvery('FETCH_VEHICLES', fetchVehicles)
    yield takeEvery('FETCH_VEHICLES_BY_FIRMWARE', fetchVehiclesByFirmware)
    yield takeEvery('FETCH_FIRMWARES_REPORT', fetchFirmwaresReport)
    yield takeEvery('FETCH_CUSTOMER_VEHICLES', fetchCustomerVehicles)
    yield takeEvery('FETCH_TEMPLATE_VEHICLES', fetchTemplateVehicles)
    yield takeEvery('FETCH_USERS', fetchUsers)
    yield takeEvery('FETCH_BOXES_REGISTERED', fetchBoxesRegistered)
    yield takeEvery('FETCH_DISCONNECTED_BOXES', fetchBoxesDisconnected)
    yield takeEvery('FETCH_DISCONNECTED_STATUS_HISTORY', fetchDisconnectedHistory)
    yield takeEvery('FETCH_USERS_REGISTERED', fetchUsersRegistered)
    yield takeEvery('FETCH_TEMPLATES', fetchTemplates)
    yield takeEvery('FETCH_TEMPLATE', fetchTemplate)
    yield takeEvery('REMOVE_TEMPLATE', postRemoveTemplate)
    yield takeLatest('FETCH_CUSTOMERS', fetchCustomers)
    yield takeEvery('FETCH_CUSTOMERS_BY_REGION', fetchCustomersByRegion)
    yield takeEvery('FETCH_CUSTOMER', fetchCustomer)
    yield takeEvery('ADD_VEHICLE_TO_CUSTOMER', addVehicleToCustomer)
    yield takeEvery('REMOVE_VEHICLE_FROM_CUSTOMER', removeVehicleFromCustomer)
    yield takeEvery('FETCH_CUSTOMER_USERS', fetchCustomerUsers)
    yield takeEvery('FETCH_WORKSHOP_USERS', fetchWorkshopUsers)
    yield takeEvery('FETCH_FIRMWARES', fetchFirmwares)
    yield takeEvery('FIRMWARE_UPLOADED', fetchFirmwares)
    yield takeEvery('FIRMWARE_ALREADY_UPLOADED', fetchFirmwares)
    yield takeEvery('FETCH_FIRMWARE_BLOB', fetchFirmwareBlob)
    yield takeEvery('UPLOAD_FIRMWARE', uploadFirmware)
    yield takeEvery('UPGRADE_FIRMWARE', designateFirmwareAsStable)
    yield takeEvery('DELETE_FIRMWARE', deleteFirmware)
    yield takeEvery('REMOTE_UPGRADE', remoteUpgrade)
    yield takeEvery('FETCH_REMOTE_UPGRADE_STATUS', fetchIsUpgrading)
    yield takeEvery('FETCH_DEBUG_STATUS', fetchDebugStatus)
    yield takeEvery('ENABLE_DEBUG_STATUS', opt => setDebugStatus(true, opt))
    yield takeEvery('DISABLE_DEBUG_STATUS', opt => setDebugStatus(false, opt))
    yield takeEvery('FETCH_DEVICE_TWIN', fetchDeviceTwin)
    yield takeEvery('FETCH_USER', fetchUser)
    yield takeEvery('SAVE_CONFIG_AS_TEMPLATE', saveConfigAsTemplate)
    yield takeEvery('APPLIED_TEMPLATE_TO_BOX', applyTemplateToBox)
}
